import {
  type CustomChart,
  type CustomChartSerie,
} from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { Box, Row } from "@tokenterminal/ui/Box"
import { ChartContext } from "@tokenterminal/ui/Chart/ChartContext"
import { type ChartSerie } from "@tokenterminal/ui/Chart/useHighchartOptions"
import { Divider } from "@tokenterminal/ui/Divider"
import { Stack } from "@tokenterminal/ui/Stack"
import { Text, TextWithCapsize } from "@tokenterminal/ui/Text/Text"
import { dequal } from "dequal"
import { Fragment, memo, Suspense, useContext, useMemo } from "react"
import { Button } from "../../../../ui/button/Button"
import { MenuButton, MenuItem } from "../../../../ui/menu/MenuButton"
import { getAvailableColor } from "../../utils/colors"
import { generateId } from "../../utils/generate-id"
import { LegendItem } from "./LegendItem"
import { LegendPlaceholder } from "./Placeholder"
type LegendProps = {
  onVisibilityChange?: (id: string, isVisible: boolean) => void
  chartSerieSettings: CustomChart
  series: ChartSerie[]
}

const DISPLAY_LIMIT = 20

function LegendRow({
  settings,
  onVisbilityChange,
  offsetCount,
  series,
}: {
  settings: CustomChartSerie
  onVisbilityChange?: (serieId: string, visibility: boolean) => void
  offsetCount: number
  series: ChartSerie[]
}) {
  const { chartApi } = useContext(ChartContext)

  const chartInfo = series.filter((serie) => serie.id === settings.id)

  const legendItems = chartInfo.slice(
    0,
    Math.min(settings.limit ?? Infinity, DISPLAY_LIMIT)
  )

  const moreItems = chartInfo.slice(DISPLAY_LIMIT, settings.limit ?? Infinity)

  return (
    <Stack gap="3x">
      <TextWithCapsize variant="secondary">{settings.title}</TextWithCapsize>
      <Row gap="4x" start flexWrap="wrap">
        {legendItems.map((info, idx) => {
          const index = offsetCount + idx
          const id = info.name
          const currentColor: string =
            settings.colors?.[idx] ?? getAvailableColor(index)

          const label = settings.groupBy
            ? (info.info?.legendLabel ?? info.name)
            : "Aggregated"

          const isVisible = settings.visible
            ? (settings.visible?.includes?.(id) ?? true)
            : true

          return (
            <LegendItem
              key={id}
              name={label}
              color={currentColor}
              serieType={settings.chart_type}
              isActive={isVisible}
              onMouseOver={() => {
                const currentSerie = chartApi?.get(
                  `serie-${id}`
                ) as Highcharts.Series | null

                // if serie is not visible, we don't trigger this action
                if (!currentSerie?.visible) {
                  return
                }

                chartInfo.forEach((info) => {
                  const serie = chartApi?.get(
                    `serie-${info.name}`
                  ) as Highcharts.Series | null
                  if (serie && info.name !== id) {
                    serie.setState("inactive", true)
                  }
                })
              }}
              onMouseOut={() => {
                chartInfo.forEach((info) => {
                  const serie = chartApi?.get(
                    `serie-${info.name}`
                  ) as Highcharts.Series | null
                  if (serie && info.name !== id) {
                    serie.setState(undefined, true)
                  }
                })
              }}
              onClick={() => {
                if (chartInfo[idx]) {
                  onVisbilityChange?.(id, !isVisible)

                  if (!onVisbilityChange) {
                    const serie = chartApi?.get(
                      `serie-${chartInfo[idx].name}`
                    ) as Highcharts.Series | null
                    if (serie) {
                      serie.setVisible(!serie.visible, true)
                    }
                  }
                }
              }}
            />
          )
        })}
        {moreItems.length > 0 && (
          <MenuButton
            trigger={
              <Button size="xsmall" variant="ghost">
                <Text size="12">+ {moreItems.length} more</Text>
              </Button>
            }
          >
            {moreItems.map((info, idx) => {
              const index = offsetCount + idx + DISPLAY_LIMIT
              const currentColor: string =
                settings.colors?.[index] ?? getAvailableColor(index)
              const id = generateId(settings.id, info.name)
              const label = settings.groupBy
                ? (info.info?.legendLabel ?? info.name)
                : "Aggregated"

              const isVisible = settings.visible
                ? (settings.visible?.includes?.(id) ?? true)
                : true

              return (
                <MenuItem
                  key={id}
                  onHoverStart={() => {
                    const currentSerie = chartApi?.get(
                      `serie-${id}`
                    ) as Highcharts.Series | null

                    // if serie is not visible, we don't trigger this action
                    if (!currentSerie?.visible) {
                      return
                    }

                    chartInfo.forEach((info) => {
                      const serie = chartApi?.get(
                        `serie-${info.name}`
                      ) as Highcharts.Series | null
                      if (serie && info.name !== id) {
                        serie.setState("inactive", true)
                      }
                    })
                  }}
                  onHoverEnd={() => {
                    chartInfo.forEach((info) => {
                      const serie = chartApi?.get(
                        `serie-${info.name}`
                      ) as Highcharts.Series | null
                      if (serie && info.name !== id) {
                        serie.setState(undefined, true)
                      }
                    })
                  }}
                  onAction={() => {
                    if (chartInfo[idx]) {
                      onVisbilityChange?.(id, !isVisible)

                      if (!onVisbilityChange) {
                        const serie = chartApi?.get(
                          `serie-${chartInfo[idx].name}`
                        ) as Highcharts.Series | null
                        if (serie) {
                          serie.setVisible(!isVisible, true)
                        }
                      }
                    }
                  }}
                >
                  <Box>
                    <LegendItem
                      name={label}
                      color={currentColor}
                      serieType={settings.chart_type}
                      isActive={isVisible}
                    />
                  </Box>
                </MenuItem>
              )
            })}
          </MenuButton>
        )}
      </Row>
    </Stack>
  )
}

export const Legend = memo(
  function Legend({
    chartSerieSettings,
    series,
    onVisibilityChange,
  }: LegendProps) {
    const offsetCounts = useMemo(() => {
      let i = 0
      let sum = 0
      const offsets = []
      for (const serie of chartSerieSettings.configs) {
        offsets[i++] = sum
        sum += series
          .filter((s) => s.id === serie.id)
          .slice(0, serie.limit ?? Infinity).length
      }

      return offsets
    }, [chartSerieSettings.configs, series])

    return (
      <Suspense fallback={<LegendPlaceholder />}>
        <Row start gap="6x" alignItems="flexStart">
          {chartSerieSettings.configs.map((chartSetting, index) => {
            return (
              <Fragment key={index}>
                {index > 0 ? <Divider vertical variant="secondary" /> : null}
                <LegendRow
                  settings={chartSetting}
                  onVisbilityChange={onVisibilityChange}
                  offsetCount={offsetCounts?.[index] ?? 0}
                  series={series}
                />
              </Fragment>
            )
          })}
        </Row>
      </Suspense>
    )
  },
  (a, b) => dequal(a, b)
)
