import { type AxisCrosshairOptions, type YAxisOptions } from 'highcharts'
import { getFormatter as getAxisFormatter } from '../axisFormatters'
import { chartCSSVars } from '../ChartContract.css'
import { SERIE_FORMAT } from '../ChartOptions'

const DEFAULT_Y_AXIS_OPTIONS: YAxisOptions = {
  labels: {
    style: {
      fontSize: chartCSSVars.axis.fontSize,
      color: chartCSSVars.axis.color,
      fontWeight: '400',
    },
    y: 4,
    reserveSpace: true,
    distance: 16,
  },
  title: {
    align: 'middle',
    style: {
      color: chartCSSVars.axis.titleColor,
      fontWeight: '500',
    },
    reserveSpace: true,
    margin: 16,
  },
  crosshair: {
    dashStyle: 'ShortDot',
  },
  margin: 8,
  showLastLabel: true,
  tickColor: chartCSSVars.axis.tickColor,
  gridLineColor: chartCSSVars.axis.gridColor,
  lineColor: chartCSSVars.axis.gridColor,
  softMin: 0,
}

function mutateAndRemoveUndefined(obj: YAxisOptions): void {
  if (Object.hasOwn(obj, 'offset') && typeof obj.offset === 'undefined') {
    delete obj.offset
  }
  if (
    obj.labels &&
    Object.hasOwn(obj.labels, 'x') &&
    typeof obj.labels?.x === 'undefined'
  ) {
    delete obj.labels.x
  }
}

export function createYAxis(
  id: string,
  {
    label,
    index,
    format,
    opposite,
    isPercentageStack = false,
    isLogarithmic = false,
    isReversed = false,
    options = {},
  }: {
    label: string
    index: number
    opposite: boolean
    format: `${SERIE_FORMAT}`
    isPercentageStack: boolean
    isLogarithmic: boolean
    isReversed: boolean
    options: Partial<YAxisOptions>
  },
): YAxisOptions {
  const axis: YAxisOptions = {
    ...DEFAULT_Y_AXIS_OPTIONS,
    id,
    opposite,
    height: '100%',
    panningEnabled: false,
    // fix to put y axis to 100
    // max: isPercentageStack ? 100 : undefined,
    softMin: isLogarithmic ? 10000 : 0,
    type: isLogarithmic ? 'logarithmic' : undefined,
    reversedStacks: isReversed,
    ...options,
    title: {
      ...DEFAULT_Y_AXIS_OPTIONS.title,
      text: label,
      // textAlign: opposite ? 'right' : 'left',
      ...options.title,
      style: {
        ...DEFAULT_Y_AXIS_OPTIONS.title!.style,
        ...options.title?.style,
      },
    },
    crosshair: {
      ...(DEFAULT_Y_AXIS_OPTIONS.crosshair as AxisCrosshairOptions),
      color: '#88888A',
    },
    labels: {
      ...DEFAULT_Y_AXIS_OPTIONS.labels,
      formatter: getAxisFormatter(
        isPercentageStack ? SERIE_FORMAT.PERCENTAGE : format,
        { isPercentageShare: isPercentageStack },
      ),
      ...options.labels,
      style: {
        ...DEFAULT_Y_AXIS_OPTIONS.labels!.style,
        ...options.labels?.style,
      },
      align: opposite ? 'left' : 'right',
    },
  }

  // remove a couple of undefined properties so highcharts uses their defaults
  mutateAndRemoveUndefined(axis)

  return axis
}
