import {
  type DataLabelsFormatterCallbackFunction,
  type TimelineDataLabelsFormatterContextObject,
} from 'highcharts'
import { SERIE_FORMAT } from './ChartOptions'
import * as sharedFormatters from './sharedFormatter'
import { type FormatterOptions, type Formatter } from './sharedFormatter'

const pricingFormatter: Formatter<TimelineDataLabelsFormatterContextObject> =
  function pricingFormatter() {
    return function () {
      return sharedFormatters.pricingFormatter(this.y, '$0a')
    }
  }

const percentageFormatter: Formatter<TimelineDataLabelsFormatterContextObject> =
  function percentageFormatter() {
    return function () {
      return sharedFormatters.percentageFormatter(this.y)
    }
  }

const ratioFormatter: Formatter<TimelineDataLabelsFormatterContextObject> =
  function ratioFormatter() {
    return function () {
      return sharedFormatters.ratioFormatter(this.y)
    }
  }

const numberFormatter: Formatter<TimelineDataLabelsFormatterContextObject> =
  function numberFormatter() {
    return function () {
      return sharedFormatters.numberFormatter(this.y)
    }
  }

const timeFormatter: Formatter<TimelineDataLabelsFormatterContextObject> =
  function timeFormatter() {
    return function () {
      return sharedFormatters.timeFormatter(this.y)
    }
  }

const bytesFormatter: Formatter<TimelineDataLabelsFormatterContextObject> =
  function bytesFormatter() {
    return function () {
      return sharedFormatters.bytesFormatter(this.y)
    }
  }

const formatters = {
  [SERIE_FORMAT.PRICE]: pricingFormatter,
  [SERIE_FORMAT.RATIO]: ratioFormatter,
  [SERIE_FORMAT.PERCENTAGE]: percentageFormatter,
  [SERIE_FORMAT.NUMBER]: numberFormatter,
  [SERIE_FORMAT.TOKEN]: numberFormatter,
  [SERIE_FORMAT.TIME]: timeFormatter,
  [SERIE_FORMAT.BYTES]: bytesFormatter,
  [SERIE_FORMAT.TOKEN_ICP]: numberFormatter,
  [SERIE_FORMAT.TOKEN_CKBTC]: numberFormatter,
  [SERIE_FORMAT.TOKEN_CKETH]: numberFormatter,
  [SERIE_FORMAT.CYCLES_PER_SECONDS]: numberFormatter,
  [SERIE_FORMAT.KWH]: numberFormatter,
} as const

export function getFormatter(
  serie: `${SERIE_FORMAT}`,
  options: FormatterOptions = {},
): DataLabelsFormatterCallbackFunction {
  return formatters[serie](options)
}
