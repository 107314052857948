import { type GranularityType } from '@tokenterminal/ui/Chart/utils/groupDataBasedOnGranularity'

export const metricFirstValueLookup: Record<
  string,
  Record<GranularityType, number>
> = {
  market_cap_fully_diluted: {
    day: 5000000,
    week: 5000000,
    month: 5000000,
    quarter: 5000000,
  },
  market_cap_circulating: {
    day: 5000000,
    week: 5000000,
    month: 5000000,
    quarter: 5000000,
  },
  bridge_deposits: {
    day: 1000000,
    week: 1000000,
    month: 1000000,
    quarter: 1000000,
  },
  net_deposits: {
    day: 1000000,
    week: 1000000,
    month: 1000000,
    quarter: 1000000,
  },
  tvl: {
    day: 1000000,
    week: 1000000,
    month: 1000000,
    quarter: 1000000,
  },
  assets_staked: {
    day: 1000000,
    week: 1000000,
    month: 1000000,
    quarter: 1000000,
  },
  capital_deployed: {
    day: 1000000,
    week: 1000000,
    month: 1000000,
    quarter: 1000000,
  },
  flash_loan_volume: {
    day: 1000000,
    week: 1000000,
    month: 1000000,
    quarter: 1000000,
  },
  total_value_locked: {
    day: 1000000,
    week: 1000000,
    month: 1000000,
    quarter: 1000000,
  },
  trading_volume: {
    day: 100000,
    week: 700000,
    month: 3000000,
    quarter: 9000000,
  },
  notional_trading_volume: {
    day: 100000,
    week: 700000,
    month: 3000000,
    quarter: 9000000,
  },
  active_loans: {
    day: 1000000,
    week: 1000000,
    month: 1000000,
    quarter: 1000000,
  },
  outstanding_supply: {
    day: 10000000,
    week: 10000000,
    month: 10000000,
    quarter: 10000000,
  },

  fees: {
    day: 1500,
    week: 10000,
    month: 40000,
    quarter: 120000,
  },
  fees_supply_side: {
    day: 1500,
    week: 10000,
    month: 40000,
    quarter: 120000,
  },
  revenue: {
    day: 1500,
    week: 10000,
    month: 40000,
    quarter: 120000,
  },
  expenses: {
    day: 1500,
    week: 10000,
    month: 40000,
    quarter: 120000,
  },
  cost_of_revenue: {
    day: 1500,
    week: 10000,
    month: 40000,
    quarter: 120000,
  },
  token_incentives: {
    day: 1500,
    week: 10000,
    month: 40000,
    quarter: 120000,
  },
  operating_expenses: {
    day: 1500,
    week: 10000,
    month: 40000,
    quarter: 120000,
  },
  gross_profit: {
    day: 1500,
    week: 10000,
    month: 40000,
    quarter: 120000,
  },
  earnings: {
    day: 1500,
    week: 10000,
    month: 40000,
    quarter: 120000,
  },
  treasury: {
    day: 1000000,
    week: 1000000,
    month: 1000000,
    quarter: 1000000,
  },
  treasury_net: {
    day: 1000000,
    week: 1000000,
    month: 1000000,
    quarter: 1000000,
  },
  user_dau: {
    day: 10,
    week: 10,
    month: 10,
    quarter: 10,
  },
  user_wau: {
    day: 100,
    week: 100,
    month: 100,
    quarter: 100,
  },
  user_mau: {
    day: 1000,
    week: 1000,
    month: 1000,
    quarter: 1000,
  },
  active_addresses_daily: {
    day: 10,
    week: 10,
    month: 10,
    quarter: 10,
  },
  active_addresses_weekly: {
    day: 100,
    week: 100,
    month: 100,
    quarter: 100,
  },
  active_addresses_monthly: {
    day: 1000,
    week: 1000,
    month: 1000,
    quarter: 1000,
  },
  token_trading_volume: {
    day: 1000,
    week: 7000,
    month: 30000,
    quarter: 90000,
  },
}

export function getFirstMetricThreshold(
  metric: string,
  granularity: GranularityType,
): number | undefined {
  return metricFirstValueLookup?.[metric]?.[granularity]
}
