// import { type ChartProps } from "./Chart"

import { type CustomChart } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { type Atom } from "jotai"
import { unwrap } from "jotai/utils"
import { useEffect, useMemo } from "react"
import { Chart, type ChartProps } from "./Chart"
import { fetchConfigByIdAtom } from "./store/fetch-config-atom"

type ChartWithConfigIdProps = Omit<ChartProps, "configAtom"> & { id: string }
export function ChartWithConfigId({ id, ...props }: ChartWithConfigIdProps) {
  useEffect(() => {
    return () => {
      // cleanup memory allocation
      fetchConfigByIdAtom.remove(id)
    }
  }, [id])

  const configAtom = useMemo(() => {
    return unwrap(fetchConfigByIdAtom(id), (prev) => {
      return prev || { configs: [], zoom: "180d", granularity: "day" }
    }) as Atom<CustomChart>
  }, [id])

  return <Chart key={id} configAtom={configAtom} {...props} />
}
