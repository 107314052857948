import { CaretRight, Check } from "@phosphor-icons/react"
import { type ReactNode } from "react"
import {
  Menu,
  MenuItem as AriaMenuItem,
  type MenuProps,
  MenuTrigger,
  type MenuTriggerProps,
  type MenuItemProps as AriaMenuItemProps,
  Separator as AriaSeparator,
  Popover,
  MenuSection as AriaMenuSection,
  type MenuSectionProps,
  Header as AriaHeader,
  type PopoverProps,
} from "react-aria-components"
import { twMerge } from "tailwind-merge"
import { Icon } from "../icon"
import { type IconProps } from "../icon/Icon"
import { Text } from "../primitives/text/Text"

export const MenuSection = ({
  className,
  ...props
}: MenuSectionProps<object>) => (
  <AriaMenuSection
    className={twMerge("tt-menu-section", className)}
    {...props}
  />
)

export const Header = ({
  className,
  ...props
}: {
  children: React.ReactNode
  className?: string
}) => (
  <AriaHeader className={twMerge("tt-section-header", className)} {...props} />
)

export const Separator = ({ className }: { className?: string }) => (
  <AriaSeparator className={twMerge("tt-separator", className)} />
)

type MenuItemProps = AriaMenuItemProps & {
  icon?: IconProps["as"]
  /** The callback to call when the item is selected. */
  onAction?: AriaMenuItemProps["onAction"]
  /** Whether to close the menu when the item is selected. */
  closeOnSelect?: boolean
  className?: string
}

export const MenuItem = ({ icon, className, ...props }: MenuItemProps) => {
  const textValue =
    props.textValue ||
    (typeof props.children === "string" ? props.children : undefined)

  return (
    <AriaMenuItem
      textValue={textValue}
      className={({ isFocused, isSelected, isOpen }) =>
        twMerge(
          "tt-menu-item",
          isFocused ? "focused" : "",
          isSelected ? "selected" : "",
          isOpen ? "open" : "",
          className
        )
      }
      {...props}
    >
      {(renderProps) => (
        <div className="flex flex-row w-full items-center gap-1">
          {icon ? (
            <Icon as={icon} className="tt-icon" />
          ) : (
            <Icon as={Check} className="tt-selected-icon" />
          )}
          {/* @ts-ignore - Fix children prop */}
          <Text size="14" flex="1 1 auto">
            {typeof props.children === "function"
              ? props.children(renderProps)
              : props.children}
          </Text>
          {renderProps.hasSubmenu && <Icon as={CaretRight} />}
        </div>
      )}
    </AriaMenuItem>
  )
}

export type MenuButtonProps<T> = MenuProps<T> &
  Omit<MenuTriggerProps, "children" | "trigger"> & {
    trigger: ReactNode
    placement?: PopoverProps["placement"]
  }

/**
 * A button that triggers a menu when clicked. Helpful for dropdowns of actions or settings.
 * The menu can contain any number of items, sections, and headers.
 *
 * ```tsx
 * import { MenuButton } from "@tokenterminal/ui/menu/MenuButton"
 * import { MenuItem, MenuSection, Separator } from "@tokenterminal/ui/menu/MenuButton"
 *
 * <MenuButton label="Actions">
 *   <MenuItem onAction={...}>Item 1</MenuItem>
 *   <MenuItem onAction={...}>Item 2</MenuItem>
 *   <Separator />
 *   <MenuSection>
 *     <Header>Section 1</Header>
 *     <MenuItem onAction={...}>Item 3</MenuItem>
 *   </MenuSection>
 * </MenuButton>
 * ```
 */

export const MenuButton = ({
  children,
  trigger,
  placement,
  ...props
}: MenuButtonProps<object>) => {
  return (
    <MenuTrigger {...props}>
      {trigger}
      <Popover className={"tt-popover"} placement={placement}>
        <Menu className={twMerge("tt-menu")} {...props}>
          {children}
        </Menu>
      </Popover>
    </MenuTrigger>
  )
}

MenuButton.Item = MenuItem
MenuButton.Separator = Separator
MenuButton.Section = MenuSection
MenuButton.Header = Header
