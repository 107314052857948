import { type CustomChart } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import {
  type ChartProps as UIChartProps,
  Chart as UIChart,
  type ChartSerie,
  type ChartSerieTimeData,
  type GranularityType,
} from "@tokenterminal/ui/Chart/Chart"
import { BarSkeleton } from "@tokenterminal/ui/Chart/ChartLoadingIndicator"
import { useAtomValue } from "jotai"
import { Suspense, useMemo } from "react"
import { getTooltipOptionsFromChartSettingsAtomFamily } from "../../store/chart/tooltip-options-atom"
import { getYAxisOptionsFromChartSettingsAtomFamily } from "../../store/chart/yaxis-options-atom"

import {
  getColorPaletteColorVariableById,
  isColorPaletteColorId,
} from "../../utils/colors"
import { convertApiToZoom } from "../../utils/convert-zoom-to-api"

type InnerBaseProps = {
  chartSerieSettings: CustomChart
  series: ChartSerie[]
  seriesData: Record<string, ChartSerieTimeData>
  isVisible?: boolean
}

type BaseChartProps = Pick<
  InnerBaseProps,
  "chartSerieSettings" | "series" | "seriesData" | "isVisible"
> & {
  hideYAxisLabels?: boolean
}

export function BaseChart({
  chartSerieSettings,
  series,
  seriesData,
  isVisible,
  hideYAxisLabels = false,
}: BaseChartProps) {
  const zoom = chartSerieSettings.zoom
  const granularity = chartSerieSettings.granularity as GranularityType
  const showCurrentPeriod = chartSerieSettings.show_current_period ?? false

  const yAxisOptions: UIChartProps["yAxisOptions"] = useAtomValue(
    getYAxisOptionsFromChartSettingsAtomFamily({
      chartSettings: chartSerieSettings.configs,
      hideYAxisLabels,
    })
  )
  const tooltipOptions = useAtomValue(
    getTooltipOptionsFromChartSettingsAtomFamily(chartSerieSettings.configs)
  )

  const seriesWithParsedColors = useMemo(() => {
    return series.map((serie) => {
      const result = JSON.parse(JSON.stringify(serie)) as ChartSerie
      if (isColorPaletteColorId(serie.color || "")) {
        // Can cast the color as a string since we know it's a color palette color ID
        result.color = getColorPaletteColorVariableById(serie.color as string)
      }

      return result
    })
  }, [series])

  return (
    <Suspense
      fallback={
        <BarSkeleton
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
      }
    >
      {isVisible ? (
        <UIChart
          series={seriesWithParsedColors}
          seriesData={seriesData}
          zoom={convertApiToZoom(zoom)}
          granularity={granularity}
          yAxisOptions={yAxisOptions}
          showNavigator={false}
          isTimeBased
          tooltipOptions={tooltipOptions}
          legend={false}
          showCurrentPeriod={showCurrentPeriod}
        />
      ) : null}
    </Suspense>
  )
}
