import { forwardRef, type HTMLAttributes } from "react"
import { twMerge } from "tailwind-merge"

const levelComponentMap = {
  1: "h1",
  2: "h2",
  3: "h3",
  4: "h4",
} as const

export type HeadingProps = HTMLAttributes<
  HTMLDivElement | HTMLHeadingElement | HTMLParagraphElement | HTMLSpanElement
> & {
  as?: "h1" | "h2" | "h3" | "h4" | "p" | "span" | "div"
  level: 1 | 2 | 3 | 4
  children: React.ReactNode
  className?: string
}

export const Heading = forwardRef<HTMLDivElement, HeadingProps>(
  ({ as = "div", children, className, level, ...props }, ref) => {
    const Component = as || levelComponentMap[level]
    if (!as) {
      as = levelComponentMap[level]
    }

    return (
      <Component
        ref={ref}
        data-level={level}
        className={twMerge(
          "font-medium text-fg-default tracking-tight data-[level='1']:text-3xl data-[level='2']:text-2xl data-[level='3']:text-xl data-[level='4']:text-base",
          className
        )}
        {...props}
      >
        {children}
      </Component>
    )
  }
)

Heading.displayName = "Heading"
