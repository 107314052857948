import { type Interval } from "@tokenterminal/tt-analytics-api-types/dist/api/zoom"
import { ZOOM_ENUM, type ZoomValues } from "../types"

export function convertApiToZoom(zoom: `${Interval}`): ZoomValues {
  switch (zoom) {
    case "24h":
      return 1
    case "7d":
      return 7
    case "30d":
      return 30
    case "90d":
      return 90
    case "180d":
      return 180
    case "365d":
      return 365
    case `max`:
      return ZOOM_ENUM.MAX
    case `ytd`:
      return ZOOM_ENUM.YTD
    case `4fullq`:
      return ZOOM_ENUM.FOUR_FULL_QUARTERS
  }

  throw new Error(`Unknown zoom value: ${zoom}`)
}
