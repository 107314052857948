export enum SERIE_FORMAT {
  PRICE = 'price',
  RATIO = 'ratio',
  PERCENTAGE = 'percent',
  NUMBER = 'number',
  TOKEN = 'token',
  TIME = 'time',
  BYTES = 'bytes',

  // temporary FORMATS
  TOKEN_ICP = 'token_ICP',
  TOKEN_CKBTC = 'token_ckBTC',
  TOKEN_CKETH = 'token_ckETH',
  CYCLES_PER_SECONDS = 'cycles_per_seconds',
  KWH = 'kwh',
}

export enum SERIE_TYPE {
  LINE = 'line',
  COLUMN = 'column',
  AREA = 'area',
}

export enum SERIE_STACK {
  PERCENTAGE = 'percent',
  NORMAL = 'normal',
}
