import { type CustomChart } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { atom } from "jotai"
import { atomFamily } from "jotai/utils"
import { fetchAtomWithRefresh } from "../../../api/store/fetch-atom"
import { unwrapWithData } from "../../../utils/jotai/unwrap"
import { withSuspendAtom } from "../../../utils/jotai/withSuspendAtom"
import { generateId } from "../utils/generate-id"
import { getProjectsFromFilters } from "../utils/get-projects-from-filter"

export const fetchConfigByIdAtom = atomFamily(function fetchConfigByIdAtom(
  configId: string
) {
  const baseAtom = fetchAtomWithRefresh(`/v3/internal/configs/${configId}`)

  const fetchConfigByIdAtom = atom(async (get) => {
    const result = unwrapWithData(await get(baseAtom))

    if (result.type !== "chart") {
      throw new Error("Invalid config type")
    }

    ;(result.config as CustomChart).configs.forEach((config) => {
      if (!config.filters) {
        config.filters = [
          {
            type: "project",
            values: config.projects ?? [],
            comparator: "in",
          },
        ]
      }

      config.visible =
        typeof config.visible === "undefined"
          ? getProjectsFromFilters(config.filters)
          : config.visible
      config.visible = config.visible.map((item) => {
        return item.startsWith(config.id) ? item : generateId(config.id, item)
      })
    })

    return result.config as CustomChart
  })

  return withSuspendAtom((get) => get(fetchConfigByIdAtom))
})
