import numeral from 'numeral'
import { formatTime } from '../../utils/date'
import { canUseNumeral } from '../../utils/numerals'
import { SERIE_FORMAT } from './ChartOptions'
import type { Point } from 'highcharts'
type Value = string | number | undefined | null

export type FormatterOptions = {
  token?: string
  postfix?: string
  isPercentageShare?: boolean // Required for non % share -series using format: "percent"
  numberFormat?: 'compact' | 'full' | 'consistent' // Add numberFormat option
}

export type Formatter<T = Point> = (
  formatterOptions: FormatterOptions,
) => (this: T) => string

export function pricingFormatter(
  value: Value,
  format = '$0.0 a',
  prefix?: string,
) {
  if (!value) {
    return `0`
  }
  if (canUseNumeral(value)) {
    return numeral(value).format(format)
  }
  return `${prefix || '$'}${value}`.toUpperCase()
}

export function percentageFormatter(
  value: Value,
  format?: string,
  prefix?: string,
) {
  if (value === null || value === undefined) {
    return ''
  }
  return `${prefix || ''}${numeral(value).format('0,0[.]0')}%`
}

export function ratioFormatter(value: Value, format?: string, prefix?: string) {
  if (value === null || value === undefined) {
    return ''
  }
  return `${prefix || ''}${numeral(value).format('0.0')}x`
}

export function numberFormatter(
  value: Value,
  format?: string,
  prefix?: string,
) {
  if (value === null || value === undefined) {
    return ''
  }

  if (format === 'consistent') {
    const times1000 = Number(value) / 1000
    return numeral(times1000).format(`${prefix ? prefix : ''}0,0`) + ' K'
  }

  const numeralFormat =
    format === 'compact'
      ? `${prefix ? prefix : ''}0.0 a`
      : format || `${prefix ? prefix : ''}0[.]0 a`

  return numeral(value).format(numeralFormat)
}

export function bytesFormatter(value: Value, format?: string, prefix?: string) {
  if (value === null || value === undefined) {
    return ''
  }
  return `${prefix || ''}${numeral(value).format('0[.]0 b')}`
}

export function timeFormatter(value: Value, format?: string, prefix?: string) {
  if (typeof value === 'undefined' || value === null) {
    return '-'
  }
  if (typeof value === 'string') {
    // Should never be :thinking:
    return `${prefix || ''}${formatTime(Number.parseInt(value))}`
  }
  return `${prefix || ''}${formatTime(value)}`
}

export const thousandsFormatter = (
  value: Value,
  format?: string,
  prefix?: string,
) => {
  if (!value && value !== 0) {
    return ''
  }

  // times value by 1000
  const times1000 = Number(value) / 1000

  return `${prefix || '$'}${numeral(times1000).format('0,0')} K`
}

function pricingFormatterWithDecimals(
  value: Value,
  format?: string,
  prefix?: string,
) {
  if (!value) {
    return `0`
  }
  if (canUseNumeral(value)) {
    if (format) {
      return numeral(value).format(format)
    }

    return numeral(value).format(
      Number(value) > 0.01 ? '$0.00 a' : '$0,0.00[00] a',
    )
  }
  return `${prefix || '$'}${value}`
}

export const formatters = {
  [SERIE_FORMAT.PRICE]: (value: Value, format?: string, prefix?: string) => {
    if (format === 'compact' || format === 'full' || format === 'consistent') {
      return numberFormatter(value, format, prefix || '$')
    }
    return pricingFormatterWithDecimals(value, format, prefix)
  },
  [SERIE_FORMAT.RATIO]: ratioFormatter,
  [SERIE_FORMAT.PERCENTAGE]: percentageFormatter,
  [SERIE_FORMAT.NUMBER]: numberFormatter,
  [SERIE_FORMAT.TOKEN]: numberFormatter,
  [SERIE_FORMAT.TIME]: timeFormatter,
  [SERIE_FORMAT.BYTES]: bytesFormatter,
  [SERIE_FORMAT.TOKEN_ICP]: numberFormatter,
  [SERIE_FORMAT.TOKEN_CKBTC]: numberFormatter,
  [SERIE_FORMAT.TOKEN_CKETH]: numberFormatter,
  [SERIE_FORMAT.CYCLES_PER_SECONDS]: numberFormatter,
  [SERIE_FORMAT.KWH]: numberFormatter,
} as const

export const formatNumber = (
  value: Value,
  format?: string | 'compact' | 'full' | 'consistent',
  metric?: { format: SERIE_FORMAT },
  prefix?: string,
) => {
  // // Check if format is one of the special formats
  // if (format === 'compact' || format === 'full' || format === 'consistent') {
  //   return numberFormatter(value, format, prefix)
  // }

  // Otherwise, pick the formatter based on the metric
  const formatter = formatters[metric?.format as SERIE_FORMAT]
  if (formatter) {
    return formatter(value, format, prefix)
  }
  return numberFormatter(value, format, prefix)
}

// Add a helper function to get the format string based on numberFormat
function getFormatStringFromNumberFormat(
  numberFormat?: 'compact' | 'full' | 'consistent',
  prefix?: string,
  defaultFormat = '0[.]0 A',
): string {
  if (numberFormat === 'compact') {
    return `${prefix || ''}0.0 a`
  } else if (numberFormat === 'consistent') {
    return `${prefix || ''}0,0`
  } else if (numberFormat === 'full') {
    return `${prefix || ''}0,0.[0000]`
  }
  return defaultFormat
}

// Update the formatter functions to use formatterOptions
export function createNumberFormatter(formatterOptions: FormatterOptions = {}) {
  return function (this: Point) {
    const { token, postfix, numberFormat } = formatterOptions
    const prefix = token ? token + ' ' : ''
    const value = this.y

    return numberFormatter(value, numberFormat, prefix) + (postfix || '')
  }
}

export function createPriceFormatter(formatterOptions: FormatterOptions = {}) {
  return function (this: Point) {
    const { token, postfix, numberFormat } = formatterOptions
    const prefix = token ? token + ' ' : '$'
    const value = this.y

    if (numberFormat) {
      return numberFormatter(value, numberFormat, prefix) + (postfix || '')
    }

    return (
      pricingFormatterWithDecimals(value, undefined, prefix) + (postfix || '')
    )
  }
}

export function createPercentageFormatter(
  formatterOptions: FormatterOptions = {},
) {
  return function (this: Point) {
    const { token, postfix } = formatterOptions
    const prefix = token ? token + ' ' : ''
    const value = this.y

    return percentageFormatter(value, undefined, prefix) + (postfix || '')
  }
}

export function createRatioFormatter(formatterOptions: FormatterOptions = {}) {
  return function (this: Point) {
    const { token, postfix } = formatterOptions
    const prefix = token ? token + ' ' : ''
    const value = this.y

    return ratioFormatter(value, undefined, prefix) + (postfix || '')
  }
}

export function createTimeFormatter(formatterOptions: FormatterOptions = {}) {
  return function (this: Point) {
    const { token, postfix } = formatterOptions
    const prefix = token ? token + ' ' : ''
    const value = this.y

    return timeFormatter(value, undefined, prefix) + (postfix || '')
  }
}

export function createBytesFormatter(formatterOptions: FormatterOptions = {}) {
  return function (this: Point) {
    const { token, postfix } = formatterOptions
    const prefix = token ? token + ' ' : ''
    const value = this.y

    return bytesFormatter(value, undefined, prefix) + (postfix || '')
  }
}
