import { Heading } from "../../../../ui/heading/Heading"

type ChartHeadingProps = {
  showTitle: boolean
  showDescription: boolean
  title: string
  description: string
  headingImageSlot?: React.ReactNode
}

export function ChartHeading({
  showTitle,
  showDescription,
  title,
  description,
  headingImageSlot,
}: ChartHeadingProps) {
  return showTitle || showDescription ? (
    <div className="flex items-center gap-4 w-full mb-4">
      {headingImageSlot}
      <div
        className="flex flex-col"
        style={{
          // Leaving some hardcoded room for buttons on the right
          width: "calc(100% - 55px)",
        }}
      >
        {showTitle && (
          <Heading level={4} className="leading-none">
            {title}
          </Heading>
        )}
        {showDescription && (
          <p className="text-sm text-fg-secondary">{description}</p>
        )}
      </div>
    </div>
  ) : null
}
