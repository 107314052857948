import { Box, type BoxProps } from "@tokenterminal/ui/Box"
import { geistCapsizeCss } from "./capsize.css"
import {
  baseTextCss,
  primaryTextCss,
  secondaryTextCss,
  failureTextCss,
  successTextCss,
} from "./Text.css"

type Color = "primary" | "secondary" | "success" | "failure" | "inherit"

export type TextProps = {
  children: React.ReactNode
  color?: Color
  size?: "10" | "12" | "14" | "16" | "20" | "24" | "inherit"
} & Omit<BoxProps, "size" | "color">

export function Text({
  children,
  as = "span",
  color = "primary",
  className,
  size = "14",
  ...props
}: TextProps) {
  return (
    <Box
      as={as}
      fontSize={size}
      className={[
        baseTextCss,
        geistCapsizeCss,
        {
          [primaryTextCss]: color === "primary",
          [secondaryTextCss]: color === "secondary",
          [successTextCss]: color === "success",
          [failureTextCss]: color === "failure",
        },
        className,
      ]}
      {...props}
    >
      {children}
    </Box>
  )
}
