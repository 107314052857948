import { QueryClient } from "@tanstack/react-query"
import { createTRPCClient, splitLink, httpLink } from "@trpc/client"
import { httpBatchLink } from "@trpc/client/links/httpBatchLink"
import { type inferRouterOutputs } from "@trpc/server"
import { createTRPCOptionsProxy } from "@trpc/tanstack-react-query"
import { getCookie } from "./cookies/get-cookie"
import type { AppRouter } from "../../../../tt-analytics-api/src/trpc/trpc-types"

let apiUrl = "https://api.tokenterminal.com"

if (process.env.NEXT_PUBLIC_USE_LOCAL_SERVER === `true`) {
  apiUrl = "http://localhost:3001"
}

if (process.env.NEXT_PUBLIC_VERCEL_ENV === `preview`) {
  apiUrl = "https://api.tokenterminal.dev"
}

const getToken = () => {
  if (getCookie("_idtoken")) {
    return null
  }

  const apiToken =
    process.env.NEXT_PUBLIC_USE_LOCAL_SERVER === `true`
      ? "6a19e3c9-0bb4-4295-975c-909d4eb4873a"
      : process.env.NEXT_PUBLIC_API_DEFAULT_TOKEN

  return apiToken ?? null
}
export const queryClient = new QueryClient()

const fetcher = (
  url: URL | RequestInfo,
  init?: RequestInit | undefined
): Promise<Response> => {
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
    "x-tt-terminal-jwt": process.env.NEXT_PUBLIC_FRONTEND_JWT ?? "",
  }

  if (typeof window !== "undefined") {
    headers["x-app-path"] = window.location.pathname
  }

  if (getToken()) {
    headers.Authorization = `Bearer ${getToken()}`
  }

  return fetch(url, {
    ...(init || {}),
    credentials: "include",
    headers,
  })
}

export const trpcClient = createTRPCClient<AppRouter>({
  links: [
    splitLink({
      condition(op) {
        // check for context property `skipBatch`
        return op.context.skipBatch === true
      },
      // when condition is true, use normal request
      true: httpLink({
        url: `${apiUrl}/trpc`,
        fetch: fetcher,
      }),
      // when condition is false, use batching
      false: httpBatchLink({
        url: `${apiUrl}/trpc`,
        fetch: fetcher,
      }),
    }),
  ],
})
export const trpc = createTRPCOptionsProxy<AppRouter>({
  client: trpcClient,
  queryClient,
})

export type RouterOutputs = inferRouterOutputs<AppRouter>
