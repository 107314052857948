import { type CustomChartSerie } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { type MetricConfiguration } from "@tokenterminal/tt-analytics-api-types/dist/api/metrics"
import { dequal } from "dequal"
import { atom } from "jotai"
import { atomFamily } from "jotai/utils"
import { suspend } from "../../../../utils/jotai/suspend"
import { unwrapWithData } from "../../../../utils/jotai/unwrap"
import { toDictionary } from "../../../../utils/toDictionary"
import { metricsConfigurationsAtom } from "./meta-atoms"

const medianAggregateMetrics = new Set([
  "transaction-fee-average",
  "block-time",
  "arpu",
  "afpu",
  "trading-volume-avg-per-trade-count",
  "trading-volume-avg-per-user",
])

function isAggregateMetric(metricConfig: MetricConfiguration) {
  return metricConfig.metric_id !== "price" && metricConfig.format !== "ratio"
}

export const isMedianMetric = (metricConfig: MetricConfiguration) => {
  return (
    metricConfig.format === "ratio" ||
    medianAggregateMetrics.has(metricConfig.slug)
  )
}

export const getTooltipOptionsFromChartSettingsAtomFamily = atomFamily(
  function (chartSettings: Array<CustomChartSerie>) {
    const tooltipOptionsFromChartSettingsAtom = atom((get) => {
      if (chartSettings.length !== 1) {
        return undefined
      }

      const metricConfigurationsDictionary = toDictionary(
        unwrapWithData(suspend(get(metricsConfigurationsAtom))),
        "slug"
      )

      const chartSetting = chartSettings[0]!
      const metricConfig = metricConfigurationsDictionary.get(
        chartSetting.metric
      )
      if (!metricConfig) {
        return undefined
      }

      return {
        sort: true,
        showTotal:
          isAggregateMetric(metricConfig) &&
          !isMedianMetric(metricConfig) &&
          chartSetting.display !== "percentage",
      }
    })

    return tooltipOptionsFromChartSettingsAtom
  },
  (a, b) => {
    if (a.length !== b.length) {
      return false
    }

    return (
      dequal(a?.[0]?.metric, b?.[0]?.metric) &&
      dequal(a?.[0]?.display, b?.[0]?.display)
    )
  }
)
