import { type CustomChartSerie } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { type YAxisOptions } from "@tokenterminal/ui/Chart/Chart"
import { paletteV3 } from "@tokenterminal/ui/tokens"
import { dequal } from "dequal"
import { atom } from "jotai"
import { atomFamily } from "jotai/utils"
import { suspend } from "../../../../utils/jotai/suspend"
import { unwrapWithData } from "../../../../utils/jotai/unwrap"
import { toDictionary } from "../../../../utils/toDictionary"
import { type RouterOutputs } from "../../../../utils/trpc"
import { metricsConfigurationsAtom } from "./meta-atoms"

type MetricConfigurations =
  RouterOutputs["metrics"]["getMetricsConfiguration"]["data"]

function shouldAxisMerge(
  currentChartSetting: CustomChartSerie,
  allChartSettings: Array<CustomChartSerie>,
  possibleSeriesIndexes: Array<number>,
  metricConfigurations: Map<string, MetricConfigurations[0]>
) {
  if (possibleSeriesIndexes.length === 1) {
    return null
  }

  const currentMetricConfig = metricConfigurations.get(
    currentChartSetting.metric
  )

  if (!currentMetricConfig) {
    return null
  }

  const axisToMergeWith = possibleSeriesIndexes.findIndex((index) => {
    const possibleChartSettingsToMergeWith = allChartSettings[index]!
    const possibleMetricConfiguration = metricConfigurations.get(
      possibleChartSettingsToMergeWith.metric
    )

    if (possibleChartSettingsToMergeWith.id === currentChartSetting.id) {
      return false
    }

    if (!possibleMetricConfiguration) {
      return false
    }

    const areBothDisplayedAsPercentageOrNoneIs =
      (currentChartSetting.display !== "percentage" &&
        possibleChartSettingsToMergeWith.display !== "percentage") ||
      (currentChartSetting.display === "percentage" &&
        possibleChartSettingsToMergeWith.display === "percentage")

    return (
      areBothDisplayedAsPercentageOrNoneIs &&
      possibleMetricConfiguration.format === currentMetricConfig.format
    )
  })

  return axisToMergeWith === -1 ? null : possibleSeriesIndexes[axisToMergeWith]!
}

export function getYaxisMergePaths(
  chartSeries: Array<CustomChartSerie>,
  metricConfigurations: Map<string, MetricConfigurations[0]>
) {
  const seriesByYAxis: {
    left: Array<number>
    right: Array<number>
  } = {
    left: [],
    right: [],
  }
  const axisMap = new Map<string, string>()

  for (const [index, chartSerie] of chartSeries.entries()) {
    if (chartSerie.yAxis === null) {
      continue
    }
    seriesByYAxis[chartSerie.yAxis].push(index)

    if (seriesByYAxis[chartSerie.yAxis].length > 1) {
      const yAxisToMergeWith = shouldAxisMerge(
        chartSerie,
        chartSeries,
        seriesByYAxis[chartSerie.yAxis],
        metricConfigurations
      )
      if (yAxisToMergeWith === null) {
        continue
      } else {
        axisMap.set(chartSerie.id, chartSeries[yAxisToMergeWith]!.id)
      }
    }
  }

  return axisMap
}

export const getYAxisOptionsFromChartSettingsAtomFamily = atomFamily(function ({
  chartSettings,
  hideYAxisLabels,
}: {
  chartSettings: Array<CustomChartSerie>
  hideYAxisLabels: boolean
}) {
  const yAxisOptionsFromChartSettingsAtom = atom((get) => {
    const metricConfigurations = toDictionary(
      unwrapWithData(suspend(get(metricsConfigurationsAtom))),
      "slug"
    )

    // @ts-ignore - ignoring till we update to not use atoms
    const yAxisMap = getYaxisMergePaths(chartSettings, metricConfigurations)

    const yAxisOptions = new Map<string, YAxisOptions>()
    for (const [index, chartSetting] of chartSettings.entries()) {
      if (chartSetting.yAxis === null) {
        yAxisOptions.set(chartSetting.id, {
          visible: false,
        })
        continue
      }

      // const yAxisName = yAxisNames.get(index) ?? new Set([chartSetting.title])
      const defaultYaxis: YAxisOptions = {
        id: chartSetting.id,
        title: {
          text: hideYAxisLabels ? "" : chartSetting.title,
        },
        visible: true,
        opposite: chartSetting.yAxis === "right",
      }

      // label should be comma separated
      if (yAxisMap.size) {
        if (!yAxisMap.has(chartSetting.id)) {
          Object.assign(defaultYaxis, {
            lineWidth: index === 0 ? 0 : 1,
            lineColor: paletteV3.border,
          })
          yAxisOptions.set(chartSetting.id, defaultYaxis)
        }
      } else {
        yAxisOptions.set(chartSetting.id, defaultYaxis)
      }
    }

    for (const chartSetting of chartSettings) {
      if (yAxisMap.has(chartSetting.id)) {
        const yAxis = yAxisOptions.get(yAxisMap.get(chartSetting.id)!)!

        const titleChunks = (yAxis?.title?.text ?? chartSetting.title).split(
          ", "
        )
        titleChunks.push(chartSetting.title)
        yAxis.title!.text = hideYAxisLabels ? "" : titleChunks.join(", ")
      }
    }

    return Array.from(yAxisOptions.values())
  })

  return yAxisOptionsFromChartSettingsAtom
}, dequal)
